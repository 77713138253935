import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SnapBricksService {

  constructor(
    public api: ApiService,
  ) { }

  mySubscription: Subscription;
	private subscriptions: Subscription[] = []; // Arreglo para almacenar todas las suscripciones
	allBricks : any = []
  private dataSubject = new BehaviorSubject<any>(null);

  // Observable al que se pueden suscribir otras partes de la app
  data$ = this.dataSubject.asObservable();

  updateData(newData: any) {
    this.dataSubject.next(newData);
  }

  getBricks(data_event){
    this.allBricks =[]
	this.cancelSus()

    if (!data_event.zone_event_get) {
			//todos los categorias y bricks
			this.api.getRef("bricks").ref
            .where('event_key', '==', data_event.key)
			.get().then(async (response)=>{
				if (response.size > 0) {
					let bricks = []
					response.forEach(async (data:any)=>{
						let item = data.data()
						//obteniendo bricks visibles y que no hayan sido eliminados desde monitor
						if (item.delete === false || item.delete === undefined) bricks.push(item);
					})

					for (let i = 0; i < bricks.length; i++) {
						const element = bricks[i];
						if (element.type == "category") {
							// this.categories.push(element)
						}else if(element.type == "brick"){
							await this.getPresentationSnap(element)
						}
					}

					console.warn(this.allBricks);

				}
			})
		}else{
			// solamente bricks de la zona en la que me encuentro
			this.api.getRef("bricks").ref
            .where('event_key', '==', data_event.key)
            .where('category', '==', data_event.default_zone)
			.get().then(async (response)=>{
				if (response.size > 0) {
					
					let bricks = []
					response.forEach(async (data:any)=>{
						let item = data.data()
						//obteniendo bricks visibles y que no hayan sido eliminados desde monitor
						if (item.delete === false || item.delete === undefined) bricks.push(item);
					})

					for (let i = 0; i < bricks.length; i++) {
						const element = bricks[i];
						if (element.type == "category") {
							// this.categories.push(element)
						}else if(element.type == "brick"){
							await this.getPresentationSnap(element)
						}
					}
				}
			})
		}
  }
  
  async getPresentationSnap(brick) {

	// Conjunto para evitar duplicados en "added"
	const processedDocs = new Set();
  
	this.mySubscription = await this.getSnapshotChanges(brick.$key)
	  .subscribe(change => {
		const docId = change.doc.id;
		const presentation = change.doc.data();
		presentation.brick_key = brick.$key;
  
		if (change.type === "added") {
		  // Si el documento ya fue procesado en "added", lo ignoramos
		  if (processedDocs.has(docId)) return;
		  processedDocs.add(docId);
  
		  brick.presentation = presentation;
		  brick.visible = (presentation.visible === false || presentation.inventory === 0) ? false : true;
  
		  // Verifica si el elemento ya está en el array antes de agregarlo
		  if (this.allBricks.findIndex(item => item.$key === brick.$key) === -1) {
			this.allBricks.push(brick);
		  }
		} 
  
		else if (change.type === "modified") {
		  // Busca el índice del elemento a modificar
		  const index = this.allBricks.findIndex(item => item.$key === brick.$key);
		  if (index !== -1) {
			this.allBricks[index] = { 
			  ...this.allBricks[index], 
			  presentation: presentation,
			  visible: (presentation.visible === false || presentation.inventory === 0) ? false : true
			};
		  }
		}
  
		this.updateData(this.allBricks);
	  }, error => {
		console.error('❌ Error in snapshot listener:', error);
	  });
  
	this.subscriptions.push(this.mySubscription);
  }
  
  

  getSnapshotChanges(brickKey: string): Observable<any> {
		return new Observable(observer => {
		  const unsubscribe = this.api.getRef(`bricks/${brickKey}/presentations`).ref
			.onSnapshot(snapshot => {
			  // Emitir cada cambio al observer
			  snapshot.docChanges().forEach(change => {
				observer.next(change);
			  });
			}, 
			error => {
			  // Emitir error en caso de que algo salga mal
			  observer.error(error);
			});
	  
		  // Retorna la función de cleanup para desuscribirse
		  return () => unsubscribe();
		});
	  }

    private suscriptionPresentations: Subscription = null

  cancelSus(){
		console.error("delete suscrip == ");
    console.log(this.subscriptions);
    
		if (this.subscriptions.length > 0) this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = []
	}

}
