import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SnapBricksService } from 'src/app/services/snapBricks/snap-bricks.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
	selector: 'app-create-order-event',
	templateUrl: './create-order-event.component.html',
	styleUrls: ['./create-order-event.component.scss'],
})
export class CreateOrderEventComponent implements OnInit {

	categoryKey: any
	event_key: any
	private dataSubscription: Subscription; // Variable para almacenar la suscripción
	copyOfOriginalBricks: any = [];
	cartUser: any = [];
	subtotalCollapsed: boolean = true;
	segmentS: any = "";
	totalOrder: number = 0;

	categoryEventKey: any = ""
	eventKey: any = ""
	constructor(
		public navParams: NavParams,
		private snapBricksService: SnapBricksService,
		public loadingCtrl: LoadingController,
		public toastController: ToastController,
		private changeDetectorRef: ChangeDetectorRef,
		public auth: AuthService,
		public api: ApiService,
		public alertController: AlertController,
		public modalController: ModalController,
	) {
	}



	ngOnInit() {
		this.categoryEventKey  = this.navParams.get('categoryEventKey');
    	this.eventKey  = this.navParams.get('eventKey');
		this.dataSubscription = this.snapBricksService.data$.subscribe(bricks => {
			console.warn(bricks);
			this.copyOfOriginalBricks = JSON.parse(JSON.stringify(bricks));
			this.checkQuantityBricks()
			this.changeDetectorRef.detectChanges()
		});

	}

	checkQuantityBricks() {

		if (this.cartUser.length > 0) {
			this.totalOrder = 0
			this.cartUser.forEach(elementCart => {
				const existElement = this.copyOfOriginalBricks.find(allBricks => allBricks.$key === elementCart.$key);
				// Si se encuentra una coincidencia, modificar el objeto del primer arreglo
				if (existElement) {
					existElement.presentation.clicked = elementCart.presentation.clicked; // Modificar el campo que necesites
					existElement.presentation.quantity = elementCart.presentation.quantity; // Modificar el campo que necesites
					existElement.presentation.subtotal = elementCart.presentation.subtotal; // Modificar el campo que necesites
					this.totalOrder += existElement.presentation.subtotal
				}

			});

			//meter la cantidad de prod 
			console.log("verificar inventarios");
		}
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.dataSubscription) {
			this.dataSubscription.unsubscribe();
		}
	}

	public cartTransactionFn = async (brick: any, transactionParam: string) => {

		const loader = await this.loadingCtrl.create({
			backdropDismiss: false,
		})
		await loader.present()

		const actFunctions = {
			'add': async () => {
				await this.toggleAddValue(brick)
			},
			'plusOne': async () => {
				await this.counterButton(brick, 'add')
			},
			'removeOne': async () => {
				await this.counterButton(brick, 'remove')
			}
		}
		await actFunctions[transactionParam]();
		const loading = await this.loadingCtrl.getTop()
		if (loading) {
			await loading.dismiss()
		}

	}

	addBrick(brick){
		if(!brick.presentation.clicked && brick.presentation.quantity == 0){
			this.cartTransactionFn(brick,"add")
		}else{
			this.cartTransactionFn(brick,"plusOne")
		}
	}

	async toggleAddValue(brick) {
		brick.presentation.clicked = true;
		brick.presentation.quantity = 0;
		await this.counterButton(brick, 'add');
	}

	async counterButton(brick, action) {
		if (action === 'add') {
			if (brick.presentation.inventory > brick.presentation.quantity) {
				console.log("agregar");
				brick.presentation.quantity += 1;
				await this.updateCartUser(brick)
			} else {
				const toast = await this.toastController.create({
					message: "Por el momento NO existe disponibilidad de este producto",
					duration: 2000,
					color: 'danger'
				})
				await toast.present();
			}
		} else if (action === "remove") {

			brick.presentation.quantity -= 1;
			if (brick.presentation.quantity <= 0) {
				brick.presentation.clicked = false;
				await this.deleteBrick(brick)
			} else {
				await this.updateCartUser(brick)
			}

		}
	}

	async deleteBrick(brick) {
		const newBricks = this.cartUser.filter(element => element.$key !== brick.$key);
		this.cartUser = newBricks
		this.calculateTotal()
}

	async updateCartUser(brick) {

		let brickInfo = {
			$key: brick.$key,
			name: brick.name,
			event_key: brick.event_key,
			category: brick.category,
			storeKey: brick.storeKey,
			storeCode: brick.storeCode,
			type: brick.type,
			presentation: brick.presentation,
			image : {
				downloadURL : brick.image.downloadURL
			}
		}


		const searchBrick = this.cartUser.findIndex(element => element.$key === brick.$key);
		if (searchBrick !== -1) {
			brickInfo.presentation.subtotal = Number(brickInfo.presentation.quantity) * Number(brickInfo.presentation.price)
			this.cartUser[searchBrick] = brickInfo
			this.calculateTotal()
		} else {
			brickInfo.presentation.subtotal = Number(brickInfo.presentation.quantity) * Number(brickInfo.presentation.price)
			this.cartUser.push(brickInfo)
			this.calculateTotal()
		}

	}

	calculateTotal(){
		this.totalOrder = 0
		this.cartUser.forEach(element => {
			this.totalOrder += element.presentation.subtotal
		});
	}

	ionViewWillEnter() {
	}

	segmentChanged(e) {
		this.segmentS = e;
	}

	async onClick() {
		await new Promise(r => setTimeout(r, 5000));
		this.checkQuantityBricks()
	}
	
	correctStructure(){
		let newStructure = []
		let a = JSON.parse(JSON.stringify(this.cartUser));

		let index = 0
		this.cartUser.forEach(element => {
			let b = a[index].presentation
			b.brick = element
			newStructure.push(b)
			index++
		});
		return newStructure
	}

	test(){

		console.error(this.cartUser);
		

		let a = this.correctStructure()
		console.warn(a);
	}

	async addOrder(loader, number) {

		for (const element of this.cartUser) {
			await this.updateInventory(element.presentation)
		}

		let bricks = this.correctStructure()

		let data = {
			account: this.auth.token,
			added_date: new Date(),
			address: false, // mandatorio = false and pickup = true
			cashChange: false,
			countryCode: this.auth.countryCode,
			coupon: "",
			phone: '',
			storeKey: this.auth.storeKey,
			number: number,
			// isEticket: true,

			//cost_admin_details: adminCost,
			total_delivery: 0,
			tip: 0,
			// numeratedZone: order.hasOwnProperty('numeredSeats') ? true : false,
			// seatsReserved: order.hasOwnProperty('numeredSeats') ? order?.zoneSelected : null,
			bricks: bricks,
			status_time: { 'delivered': new Date() },
			status: 'delivered',
			payment: {
				type: this.segmentS
			},
			pickup: true,
			systemOs: 'web',
			scheduled : false,
			event_order : true,
			total: this.totalOrder,
			change: this.totalOrder,
			event_key : this.eventKey,
			event_category_key : this.categoryEventKey,
		}
		await this.api.addDocument("orders", data);
		loader.dismiss()

		Swal.fire({
            position: 'top-end',
            icon: "success",
            title: `Tú orden ha sido creada, # ${data.number}`,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });

		this.modalController.dismiss(data);
	}

	async generateOrder() {
		const loader = await this.loadingCtrl.create({
			backdropDismiss: false,
		})
		await loader.present()
		this.createNumberOrder(loader)
	}

	private getRndInteger = () => {
		let min = 1;
		let max = 99_999_999;
		return Math.floor(Math.random() * (max - min)) + min;
	}

	createNumberOrder(loader) {
		let number = this.getRndInteger();
		this.api.getRef('orders').ref
			.where('number', '==', number)
			.get()
			.then(async snapshots => {
				console.log(snapshots.size);
				// 1 = existe ||  0 = no existe
				if (snapshots.size == 0) {
					await this.getInventory(loader, number)
				} else {
					this.createNumberOrder(loader)
				}
			})
	}

	async getInventory(loader, number) {
		let have_inventory = true;
		let noInventoryName = [];

		for (const element of this.cartUser) {
			console.log(element);
			await this.api.getDocument(`bricks/${element.$key}/presentations/`, element.presentation.$key).then(data => {
				if (element.presentation.quantity > data['inventory']) {
					have_inventory = false;
					element.missing_inventory = true;
					element.inventory = data['inventory'];
					noInventoryName.push("<h5>" + element.name + "</h5>");
				}
			})
		}

		if (have_inventory) {
			console.warn("TODO BIEN HACE COMPRA");
			await this.addOrder(loader, number)
		} else {
			this.inventoryMessage(loader, number, noInventoryName);
		}
	}

	async inventoryMessage(loader, number, product) {

		const loading = await this.loadingCtrl.getTop()
		if (loading) {
			await loading.dismiss()
		}
		loader.dismiss();
		let newProd = []
		for (let i = 0; i < product.length; i++) {
			const element = product[i];
			let styleProd = `${element}`
			newProd.push(styleProd)
		}
		let products = `${newProd.join('')}`
		this.alertController.create({
			cssClass: 'alertStyle',
			header: 'Inventario insuficiente',
			message: `${products}`,
			buttons: ['OK']
		}).then(alert => {
			alert.present();
		})
	}

	async updateInventory(element) {
		
        let AllPresentations = await this.api.getAllDocuments(`bricks/${element.brick_key}/presentations`).then(presentations => {

            if (presentations) {
                //let presentation = presentations.filter((presentations) => presentations.visible != false)
                return presentations
            } else {
                presentations = []; //no hay presentacion en el brick
            }
            return presentations
        });

        let findPresentation = AllPresentations.find(presentation => presentation.$key === element.$key);

        if (findPresentation) {
            let inventarioActual = Number(findPresentation['inventory']) - Number(element.quantity)
            let flagVisible = true;
            let flagMain = false;
            let update_data = {};

            if (findPresentation.hasOwnProperty('main')) flagMain = element.main;
            update_data["inventory"] = inventarioActual
            update_data["main"] = flagMain

            if (inventarioActual <= 10) update_data["inventory_visible"] = true;

            if (inventarioActual <= 0) {
                flagVisible = false;
                update_data["inventory"] = 0
                update_data["inventory_visible"] = false;
            }

            if (!flagVisible) {
                let props = [];
                Object.entries(findPresentation).forEach(([key, partner]) => {
                    if (key.includes("visible") && key != "inventory_visible") props.push(key);
                });

                if (props.length > 0) {
                    for (let j = 0; j < props.length; j++) {
                        const element = props[j];
                        update_data[`${element}`] = false;
                    }
                }
            }

            Object.assign(findPresentation, update_data); // modificacion de objeto que se esta evaluando para obtener nuevos valores

            await this.api.updateDocument(`bricks/${element.brick_key}/presentations`, element.$key, update_data).then(async data => { }).catch(e => {
                console.log(e);
            });

            // verificando si hay alguna presentacion disponible
            let presentation_visible = AllPresentations.filter(presentation => presentation.visible != false);

            if (presentation_visible.length == 0) {
                this.api.updateDocument('bricks', element.brick_key, {
                    visible: false,
                }).catch(e => {
                    console.log(e);

                })
            }
        }
    }

	isButtonEnabled(): boolean {
		const condition = (this.cartUser.length > 0 && this.segmentS != "");
		return condition;
	  }
}
